<template>
  <div class="sign-pc" >
    <VueSignaturePad ref="signaturePad" :class="[direction]"
    :width="signatureWidth" :height="signatureHeight"
    @on-draw="syncSignature" 
    @on-save="finishSignatureMobile"
    :options="options" />
    <div v-if="false && isPCClose" style="position:absolute;color:white;z-index:100000;top:0px;bottom:0px;right:0px;left:0px;background-color:rgba(0,0,0,0.75);display:flex;justify-content:center;align-items:center;">
      <div style="transform1: rotate(90deg);text-align:center;line-height:100vw;width:100vh;height:100vw;display:inline-block;">PC端已关闭或连接中断，请重新扫码进行签名</div>
    </div>
  </div>
</template>
<script>
const websocketUrl = process.env.VUE_APP_WEBSOCKET_BASE_URL;

// import VueSignaturePad from '@/components/VueSignaturePad/index.vue';
export default {
  components:{
    // VueSignaturePad
  },
  data () {
    return {
      signatureWidth: 600,
      signatureHeight: 200 ,
      options: {
        penColor: "#000",
        title: true,
        clear: true,
        undo:true,
        confirm: true
        // backgroundColor: 'rgba(255,255,255,1)',
      },
      qrcode: '',
      random: '',
      signData: '',
      isPCClose: false,
      lastBeat: new Date(),
      beatCheckHandler: null,
      rotate: 0,
      signReady: true,
      direction: 'vertical'
    }
  },
  computed: {
    deviceType: function () {
      var userAgent = navigator.userAgent.toLocaleLowerCase();
      if (userAgent.indexOf("android") > -1) {
        return 'android'
      } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipod") > -1) {
        return 'ios'
      } else {
        return 'other'
      }
    }
  },
  created () {
    this.random = this.$route.query.random;

    // var evt = "onorientationchange" in window ? "orientationchange" : "resize";
    // window.removeEventListener(evt, this.orientationChange)
    // window.addEventListener(evt, this.orientationChange, false);

  },
  mounted () {
    this.initWebSocket();
    this.beatCheckHandler = setInterval(() => {
      const now = new Date();
      if (now.getTime() - this.lastBeat.getTime() > 2200) {
        this.isPCClose = true;
      } else {
        this.isPCClose = false;
      }
    }, 1000)
     
    
  },
  beforeUnmount () {
    this.ws.close('close');
    this.beatCheckHandler && clearInterval(this.beatCheckHandler)
  },
  methods: {
    initWebSocket () {
      // 初始化webSocket链接
      this.ws = new WebSocket(`${websocketUrl}${this.random.toString()}-APPLET`);
      this.ws.onopen = () => {
        console.log('WebSocket连接成功');
      }
      this.ws.onmessage = (event) => {
        if (event.data === '心跳包检测') {
          this.lastBeat = new Date();
        } else {
          console.log('onmessage', event.data);
        }
        // this.$message(event.data)
      }
      this.ws.onerror = (error) => {
        console.log(error);
      }
      this.ws.onclose = (closeEvent) => {
        if (closeEvent != 'close') {
          console.log('WebSocket连接关闭', closeEvent);
          this.initWebSocket();
        }
      }
    },
    connectWebsocket () {
      this.initWebSocket()
    },
    closeWebsocket () {
      this.ws.close();
    },
    closeSign () {

    },
    saveSign () {

    },
    syncSignature () {
      if (this.$refs.signaturePad && this.ws) {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
       if (data){
          this.signData = data;
          this.ws.send(JSON.stringify({ data: data, deviceType: this.deviceType, rotate: this.rotate }))
          }
        else{
          this.ws.send('')
          }
      }
    },
    finishSignatureMobile(){
        this.ws.send(JSON.stringify({ data: 'finish', deviceType: this.deviceType, rotate: this.rotate }))
        this.$router.push({name:'signPCFinish'})
    },
    undoSignatureMobile () {
      this.$refs.signaturePad.undoSignature()
      setTimeout(() => {
        this.syncSignature();
      }, 1000)
    },
    clearSignatureMobile () {
      this.$refs.signaturePad.clearSignature()
      setTimeout(() => {
        this.syncSignature();
      }, 1000)
    },
    // base64图片旋转方法
    async rotateBase64Img (src, edg) {
      return new Promise((resolve, reject) => {
        if (!src) resolve('');
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var imgW; //图片宽度
        var imgH; //图片高度
        var size; //canvas初始大小
        if (edg % 90 != 0) {
          console.error("旋转角度必须是90的倍数!");
          throw '旋转角度必须是90的倍数!';
        }
        (edg < 0) && (edg = (edg % 360) + 360)
        const quadrant = (edg / 90) % 4; //旋转象限
        const cutCoor = {
          sx: 0,
          sy: 0,
          ex: 0,
          ey: 0
        }; //裁剪坐标
        var image = new Image();
        image.crossOrigin = "anonymous"

        image.onload = () => {
          imgW = image.width;
          imgH = image.height;
          size = imgW > imgH ? imgW : imgH;
          canvas.width = size * 2;
          canvas.height = size * 2;
          switch (quadrant) {
            case 0:
              cutCoor.sx = size;
              cutCoor.sy = size;
              cutCoor.ex = size + imgW;
              cutCoor.ey = size + imgH;
              break;
            case 1:
              cutCoor.sx = size - imgH;
              cutCoor.sy = size;
              cutCoor.ex = size;
              cutCoor.ey = size + imgW;
              break;
            case 2:
              cutCoor.sx = size - imgW;
              cutCoor.sy = size - imgH;
              cutCoor.ex = size;
              cutCoor.ey = size;
              break;
            case 3:
              cutCoor.sx = size;
              cutCoor.sy = size - imgW;
              cutCoor.ex = size + imgH;
              cutCoor.ey = size + imgW;
              break;
          }


          ctx.translate(size, size);
          ctx.rotate(edg * Math.PI / 180);
          ctx.drawImage(image, 0, 0);
          var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
          if (quadrant % 2 == 0) {
            canvas.width = imgW;
            canvas.height = imgH;
          } else {
            canvas.width = imgH;
            canvas.height = imgW;
          }
          ctx.putImageData(imgData, 0, 0);
          resolve(canvas.toDataURL())
        };

        image.src = src;
      })
    },
    setSignature(){
      this.$refs.signaturePad.fromDataURL();
    },
    //裁剪签名空白区，并返回图片
    async cropSignatureCanvas (url, padding = 0) {
      return new Promise((resolve, reject) => {
        // create canvas
        if (!url) resolve('')
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // create image
        const image = new Image();
        image.onload = draw;
        image.src = url;
        image.crossOrigin = "Anonymous";

        function draw () {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const { data, width, height } = imageData;

          // 裁剪需要的起点和终点,初始值为画布左上和右下点互换设置成极限值。
          let startX = width,
            startY = height,
            endX = 0,
            endY = 0;

          /*
          col为列，row为行，两层循环构造每一个网格，
          便利所有网格的像素，如果有色彩则设置裁剪的起点和终点
          */
          for (let col = 0; col < width; col++) {
            for (let row = 0; row < height; row++) {
              // 网格索引
              const pxStartIndex = (row * width + col) * 4;

              // 网格的实际像素RGBA
              const pxData = {
                r: data[pxStartIndex],
                g: data[pxStartIndex + 1],
                b: data[pxStartIndex + 2],
                a: data[pxStartIndex + 3]
              };

              // 存在色彩：不透明
              const colorExist = pxData.a !== 0;

              /*
              如果当前像素点有色彩
              startX坐标取当前col和startX的最小值
              endX坐标取当前col和endX的最大值
              startY坐标取当前row和startY的最小值
              endY坐标取当前row和endY的最大值
              */
              if (colorExist) {
                startX = Math.min(col, startX);
                endX = Math.max(col, startX);
                startY = Math.min(row, startY);
                endY = Math.max(row, endY);
              }
            }
          }

          // 右下坐标需要扩展1px,才能完整的截取到图像
          endX += 1;
          endY += 1;

          // 加上padding
          startX -= padding;
          startY -= padding;
          endX += padding;
          endY += padding;

          // 根据计算的起点终点进行裁剪
          const cropCanvas = document.createElement("canvas");
          const cropCtx = cropCanvas.getContext("2d");
          cropCanvas.width = endX - startX;
          cropCanvas.height = endY - startY;
          cropCtx.drawImage(
            image,
            startX,
            startY,
            cropCanvas.width,
            cropCanvas.height,
            0,
            0,
            cropCanvas.width,
            cropCanvas.height
          );

          //设置白色背景
          const bgImage = cropCtx.getImageData(0, 0, cropCanvas.width, cropCanvas.height);
          for (let i = 0; i < imageData.data.length; i += 4) {
            // 当该像素是透明的，则设置成白色
            if (bgImage.data[i + 3] === 0) {
              bgImage.data[i] = 255;
              bgImage.data[i + 1] = 255;
              bgImage.data[i + 2] = 255;
              bgImage.data[i + 3] = 255;
            }
          }
          cropCtx.putImageData(bgImage, 0, 0);

          // rosolve裁剪后的图像字符串
          resolve(cropCanvas.toDataURL());
        }

      })
    },
  }
}
</script>
<style lang="scss" scoped>

.sign-pc{
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
}
</style>
