var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-pc" },
    [
      _c("VueSignaturePad", {
        ref: "signaturePad",
        class: [_vm.direction],
        attrs: {
          width: _vm.signatureWidth,
          height: _vm.signatureHeight,
          options: _vm.options,
        },
        on: {
          "on-draw": _vm.syncSignature,
          "on-save": _vm.finishSignatureMobile,
        },
      }),
      false && _vm.isPCClose
        ? _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                color: "white",
                "z-index": "100000",
                top: "0px",
                bottom: "0px",
                right: "0px",
                left: "0px",
                "background-color": "rgba(0,0,0,0.75)",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    transform1: "rotate(90deg)",
                    "text-align": "center",
                    "line-height": "100vw",
                    width: "100vh",
                    height: "100vw",
                    display: "inline-block",
                  },
                },
                [_vm._v("PC端已关闭或连接中断，请重新扫码进行签名")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }